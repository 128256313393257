.links {
    display: flex;
    flex-direction: column;
    margin: auto;

    a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        max-width: 300px;
        width: 80vw;
        background-color: #424242;
        color: white;
        border: none;
        border-radius: 10px;
        text-decoration: none;
        font-size: 1vh;

        img {
            height: 5vh;

            &#one {
                opacity: 0;
            }
        }
    }

    .link {
        margin: 2vh 0;
        padding: 10px;

        h2 {
            margin: 0;
        }
    }
}