html,
body {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

@font-face {
  font-family: bit;
  src: url('./assets/fonts/8-BIT WONDER.TTF');
}

* {
  font-family: 8-bit;
}