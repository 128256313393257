h1 {
    font-size: 2vh;
    display: flex;
    flex-direction: column;
    -webkit-text-stroke: 0.05vh black;
    text-shadow: 0.3vh 0.3vh black;
    margin: 0 5vw 50px;

    &.e100 {
        font-size: 6vh;
        color: white;
        animation: change-color 3s infinite;
        // animation-iteration-count: infinte;
    }

    span {
        padding: 5px 0;
        font-size: 4vh;
    }

    span#dash {
        line-height: 0;
        font-size: 7vh;
    }
}

@keyframes change-color {
    0% { color: white; }
    20% { color: yellow; }
    40% { color: rgb(249, 89, 89); }
    60% { color: rgb(49, 193, 49); }
    80% { color: rgb(68, 123, 241); }
    100% { color: white; }
}