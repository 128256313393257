.background {
    background-color: #76cbe0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
    height: auto;
    font-family: bit;
    color: white;

    .sky {
        display: flex;
        flex-direction: column;

        


        img#clouds {
            width: 90vw;
            margin: 30px auto 0;
        }
    }

    .ground-elements {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        img#bushes {
            width: 100vw;
        }
    
        img#ground {
            width: 100vw;
        }
    }
}